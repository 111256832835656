
              @import "@/assets/css/variables.scss";
            





































// @import '@/assets/css/pages/register/authority.scss';
.empty_box {
  height: 80vh;
  padding: 0 40px;

  @include screen-mobile {
    padding: 0 24px;
  }
}

.btn_box {
  margin-top: 24px;
}
